.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  height: 398px;
  width: 100%;
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.ctInputsShow {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  text-align: left;
}

.ctInputsHide {
  display: none;
}

.ctBalance {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
}

.balanceText {
  color: rgb(132, 142, 156);
  margin: 10px 0;
}

.balanceValue {
  margin: 10px 5px;
}

.inputField {
  margin: 10px 0;
}

.presetField {
  width: 90%;
}

.confirmBuy {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  background-color: rgb(14, 203, 129);
  border-radius: 5px;
  border: none;
  height: 40px;
  width: 90%;
  margin-top: 10px;
}

.confirmSell {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  background-color: rgb(246, 70, 93);
  border-radius: 5px;
  border: none;
  height: 40px;
  width: 90%;
  margin-top: 10px;
}

.ctMsg {
  text-align: left;
}

.msgTitle {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  line-height: 5px;
}

.msgBody {
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

button:hover {
  cursor: pointer;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .ctInputsShow {
    width: 100%;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .ctInputsShow {
    width: 100%;
  }
}
