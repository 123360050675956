.container {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
  overflow: hidden;
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.container p:hover {
  cursor: pointer;
}

.ctLabels {
  display: flex;
  flex-direction: row;
  padding-left: 5px;
  padding-right: 5px;
}

.colLabelLeft {
  flex: 1;
  margin: 5px 0;
  text-align: left;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: 400;
}

.colLabelRight {
  flex: 1;
  margin: 5px 0;
  text-align: right;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: 400;
}

.ctTrades {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
}

.ctTradesSell {
  display: flex;
  flex-direction: row;
  height: 100%;
  overflow: auto;
  transform: scaleX(-1);
}

.ctSell {
  display: flex;
  flex-direction: row;
  transform: scaleX(-1);
}

.ctCol {
  flex: 1;
  width: 25%;
  padding-left: 5px;
  padding-right: 5px;
}

.bookItemBuy {
  margin: 5px 0;
  color: rgb(14, 203, 129);
  text-align: left;
}

.bookItemSell {
  margin: 5px 0;
  color: rgb(246, 70, 93);
  text-align: right;
}

.bookItemTextLeft {
  margin: 5px 0;
  color: rgb(183, 189, 198);
  text-align: left;
}

.bookItemTextRight {
  margin: 5px 0;
  color: rgb(183, 189, 198);
  text-align: right;
}
