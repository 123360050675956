.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  width: 100%;
}

.tab {
  border: none;
  background-color: transparent;
}

.label {
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
}

.selectedLabel {
  color: rgb(37, 150, 190);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
}
