.container {
  display: flex;
  flex-direction: column;
  border-top: solid 0.3px rgb(37, 41, 48);
}

.tabs {
  max-width: 30%;
}

.ctMsg {
  text-align: left;
}

.msgTitle {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  line-height: 5px;
}

.msgBody {
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .container {
    border: none;
  }

  .tabs {
    height: 35px;
    max-width: 100%;
  }

  .mobileCancelAll {
    margin: 0;
    padding: 0;
    padding-top: 10px;
    padding-right: 15px;
    text-align: right;
    border: none;
    background-color: transparent;
    color: rgb(37, 150, 190);
    font-size: 12px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    user-select: none; /* Standard syntax */
    -ms-user-select: none; /* IE 10 and IE 11 */
    -webkit-user-select: none; /* Safari */
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .container {
    border: none;
  }

  .tabs {
    height: 35px;
    max-width: 100%;
  }

  .mobileCancelAll {
    margin: 0;
    padding: 15px 0;
    padding-right: 15px;
    text-align: right;
    border: none;
    background-color: transparent;
    color: rgb(37, 150, 190);
    font-size: 12px;
    font-family: "IBM Plex Sans", sans-serif;
    font-weight: 400;
    user-select: none; /* Standard syntax */
    -ms-user-select: none; /* IE 10 and IE 11 */
    -webkit-user-select: none; /* Safari */
  }
}
