.container {
  display: flex;
  flex-direction: column;
}

.tabs {
  height: 35px;
  max-width: 100%;
  padding-bottom: 10px;
}

.content {
  height: 350px;
  width: 100%;
}

.graphic {
  height: 350px;
  width: 100%;
}
