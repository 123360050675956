.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 250px;
  width: 100%;
  border-left: solid 0.3px rgb(37, 41, 48);
  border-bottom: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.container button:hover,
.container p:hover {
  cursor: pointer;
}

.searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: rgb(43, 49, 57);
  border-radius: 5px;
  height: 30px;
  width: 97%;
  margin: 5px;
}

.icon {
  padding: 7px;
}

.searchInput {
  background-color: rgb(43, 49, 57);
  color: rgb(234, 236, 239);
  height: 15px;
  width: 100%;
  border: none;
  outline: none;
  padding: 3px;
}

.ctFilterButtons {
  display: flex;
  flex-direction: row;
}

.filterButton {
  color: rgb(132, 142, 156);
  font-weight: 500;
  background-color: transparent;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
}

.selectedFilterButton {
  color: rgb(37, 150, 190);
  font-weight: 500;
  background-color: transparent;
  border: none;
  margin-left: 10px;
  margin-right: 10px;
}

.ctLabels {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.labelLeft {
  width: 20%;
  text-align: left;
  margin: 0;
  padding-top: 15px;
  color: rgb(132, 142, 156);
  font-size: 12px;
  max-height: 25px;
}

.labelRight {
  width: 20%;
  text-align: right;
  margin: 0;
  padding-top: 15px;
  padding-right: 10px;
  color: rgb(132, 142, 156);
  font-size: 12px;
  max-height: 25px;
}

.ctPairList {
  height: 100%;
  padding-top: 10px;
  overflow-y: scroll;
}

.ctPairItem {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  max-height: 25px;
}

.selectedPairItemLeft {
  color: rgb(37, 150, 190);
  display: flex;
  flex-direction: row;
  width: 20%;
  text-align: left;
}

.pairItemLeft {
  display: flex;
  flex-direction: row;
  width: 20%;
  text-align: left;
}

.pairItemRight {
  width: 20%;
  text-align: right;
}

.pairAsset {
  color: rgb(132, 142, 156);
}

.valueBuy {
  align-self: center;
  color: rgb(14, 203, 129);
  font-size: 12px;
}

.valueSell {
  align-self: center;
  color: rgb(246, 70, 93);
  font-size: 12px;
}

.varPositive {
  color: rgb(14, 203, 129);
  font-size: 12px;
}

.varNegative {
  color: rgb(246, 70, 93);
  font-size: 12px;
}
