.backdrop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.6);
}

.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 500px;
  width: 100%;
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  position: absolute;
  align-self: center;
  background-color: rgb(20, 21, 26);
  border: solid 0.3px rgb(37, 41, 48);
  position: fixed;
  bottom: 0;
  z-index: 999;
}

.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
}

.icon :hover {
  cursor: pointer;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.selectedBuy {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  background-color: rgb(14, 203, 129);
  border-radius: 5px;
  border: none;
  height: 30px;
  width: 45%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.selectedSell {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  background-color: rgb(246, 70, 93);
  border-radius: 5px;
  border: none;
  height: 30px;
  width: 45%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.notSelectedButton {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  background-color: rgb(30, 32, 38);
  border-radius: 5px;
  border: none;
  height: 30px;
  width: 45%;
  margin-top: 10px;
  margin-bottom: 10px;
}
