.container {
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  height: 75px;
  width: 100%;
  position: fixed;
  bottom: 0;
  background-color: rgb(30, 32, 38);
}

.hidden {
  display: none;
}

.buyButton {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  background-color: rgb(14, 203, 129);
  border-radius: 5px;
  border: none;
  height: 40px;
  width: 40%;
}

.sellButton {
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 700;
  background-color: rgb(246, 70, 93);
  border-radius: 5px;
  border: none;
  height: 40px;
  width: 40%;
}
