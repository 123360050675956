.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  text-align: left;
  height: 130px;
  width: 100%;
  border-bottom: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.ctCol {
  padding-top: 10px;
}

.ctInfoPair {
  padding: 10px;
  max-height: 35px;
}

.ctInfoPair :hover {
  cursor: pointer;
}

.ctInfo {
  padding: 10px;
  max-height: 35px;
}

.ctRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.crypto {
  margin: 0;
  padding-right: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 25px;
}

.icon {
  color: rgb(132, 142, 156);
}

.asset {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 14px;
}

.valueBuy {
  color: rgb(14, 203, 129);
  font-size: 24px;
  margin: 0;
}

.valueSell {
  color: rgb(246, 70, 93);
  font-size: 24px;
  margin: 0;
}

.info {
  margin: 0;
}

.label {
  color: rgb(132, 142, 156);
  font-size: 10px;
  font-weight: 400;
  margin: 0;
  margin-bottom: 5px;
}

.varPositive {
  color: rgb(14, 203, 129);
}

.varNegative {
  color: rgb(246, 70, 93);
}
