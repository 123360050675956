.container {
  display: flex;
  flex-direction: column;
  height: 825px;
  width: 100%;
  border-right: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.container p:hover {
  cursor: pointer;
}

.title {
  text-align: center;
  font-size: 14px;
}

.ctLabels {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
}

.colLabelLeft {
  flex: 1;
  margin: 5px 0;
  text-align: left;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: 400;
}

.colLabelRight {
  flex: 1;
  margin: 5px 0;
  text-align: right;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: 400;
}

.ctTradesSell {
  display: flex;
  flex-direction: row-reverse;
  /* align-items: flex-end; */
  padding-left: 15px;
  padding-right: 15px;
  height: 40%;
  overflow: hidden;
}

.ctTradesSell:hover {
  overflow: scroll;
  padding-right: 7px;
}

.ctTradesBuy {
  display: flex;
  flex-direction: row;
  padding-left: 15px;
  padding-right: 15px;
  height: 40%;
  overflow: hidden;
}

.ctTradesBuy:hover {
  overflow: scroll;
  padding-right: 7px;
}

.ctCol {
  flex: 1;
}

.bookItemBuy {
  margin: 5px 0;
  color: rgb(14, 203, 129);
  text-align: left;
}

.bookItemSell {
  margin: 5px 0;
  color: rgb(246, 70, 93);
  text-align: left;
}

.bookItemText {
  margin: 5px 0;
  color: rgb(183, 189, 198);
  text-align: right;
}

.spread {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: center;
  margin: 10px 0;
  text-align: center;
  font-size: 14px;
}
