.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: rgb(43, 49, 57);
  border-radius: 5px;
  height: 40px;
  width: 90%;
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.inputPrefix {
  padding-left: 10px;
  color: rgb(132, 142, 156);
}

.ctInputEnd {
  display: flex;
  flex-direction: row;
}

.inputField {
  display: flex;
  text-align: right;
  border: none;
  outline: none;
  background-color: transparent;
  color: rgb(234, 236, 239);
}

.inputSuffix {
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(234, 236, 239);
}
