.container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  width: 100%;
  border-bottom: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.ctLogo {
  align-self: center;
  height: 30px;
  width: 150px;
  padding-left: 20px;
}

.logo {
  height: 100%;
  width: 100%;
}

.ctMenu {
  display: flex;
  align-items: center;
  padding-right: 20px;
}

.menuButton {
  text-decoration: none;
  padding-right: 15px;
  color: rgb(234, 236, 239);
}

.icon {
  display: none;
}

.signIn {
  border: none;
  background-color: transparent;
  margin-right: 20px;
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.signUp {
  border: none;
  border-radius: 5px;
  padding: 10px;
  margin-right: 30px;
  color: rgb(0, 0, 0);
  background-color: rgb(37, 150, 190);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.menuButton:hover {
  cursor: pointer;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .container {
    height: 50px;
  }

  .ctLogo {
    padding-left: 10px;
  }

  .icon {
    display: block;
    align-self: center;
    padding-right: 10px;
  }

  .icon :hover {
    cursor: pointer;
  }

  .signUp {
    margin-right: 20px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .container {
    height: 50px;
  }

  .ctLogo {
    padding-left: 10px;
  }

  .icon {
    display: block;
    align-self: center;
    padding-right: 10px;
  }

  .icon :hover {
    cursor: pointer;
  }
}
