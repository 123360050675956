.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.content {
  display: flex;
  flex-direction: column;
  border: solid 0.3px rgb(37, 41, 48);
  border-top: none;
  width: 80%;
}

.ctMiddle {
  display: flex;
  flex-direction: row;
}

.ctMiddleLeft {
  width: 75%;
}

.ctMiddleLeftCenter {
  display: flex;
  flex-direction: row;
}

.ctMiddleLeftCenterLeft {
  width: 30%;
}

.ctMiddleLeftCenterRight {
  width: 70%;
}

.ctMiddleRight {
  height: 650px;
  width: 25%;
}

.ctBottom {
  display: flex;
  flex-direction: column;
}

@media screen and (max-width: 1855px) {
  .content {
    width: 90%;
  }
}

@media screen and (max-width: 1685px) {
  .content {
    width: 100%;
  }
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .content {
    border: none;
  }

  .midTabs {
    height: 415px;
    border-bottom: solid 0.3px rgb(37, 41, 48);
  }

  .bottomTabs {
    padding-bottom: 100px;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .content {
    border: none;
  }

  .midTabs {
    height: 415px;
    border-bottom: solid 0.3px rgb(37, 41, 48);
  }

  .bottomTabs {
    padding-bottom: 100px;
  }
}

/* Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: grey transparent;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: grey;
  border: transparent;
}
