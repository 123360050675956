.container {
  display: flex;
  flex-direction: row;
  text-align: center;
  height: 75px;
  width: 100%;
  border-bottom: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.pair {
  padding-left: 20px;
  padding-right: 20px;
  align-self: center;
  font-size: 20px;
  font-weight: 500;
}

.ctNumbers {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  border-left: solid 0.3px rgb(37, 41, 48);
}

.valueBuy {
  align-self: center;
  color: rgb(14, 203, 129);
  font-size: 16px;
}

.valueSell {
  align-self: center;
  color: rgb(246, 70, 93);
  font-size: 16px;
}

.ctInfo {
  flex-direction: column;
}

.label {
  text-align: left;
  color: rgb(132, 142, 156);
  font-weight: 400;
  margin: 0;
  margin-bottom: 5px;
}

.info {
  text-align: left;
  margin: 0;
}

.varPositive {
  color: rgb(14, 203, 129);
}

.varNegative {
  color: rgb(246, 70, 93);
}
