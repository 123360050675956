.container {
  display: flex;
  flex-direction: row;
  height: 500px;
  width: 100%;
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  overflow-y: scroll;
}

.ctCol {
  width: 100%;
}

.labelLeft {
  margin: 0;
  padding: 15px 0;
  padding-left: 15px;
  text-align: left;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.labelRight {
  margin: 0;
  padding: 15px 0;
  text-align: right;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.lastLabel {
  margin: 0;
  padding: 15px 0;
  padding-right: 15px;
  text-align: right;
  border: none;
  background-color: transparent;
  color: rgb(37, 150, 190);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  user-select: none; /* Standard syntax */
  -ms-user-select: none; /* IE 10 and IE 11 */
  -webkit-user-select: none; /* Safari */
}

.lastLabelGrey {
  margin: 0;
  padding: 15px 0;
  padding-right: 15px;
  text-align: right;
  border: none;
  background-color: transparent;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.ctList {
  overflow-y: scroll;
  scrollbar-width: none; /* Mozilla */
  -ms-overflow-style: none; /* IE and Edge */
}

.ctList::-webkit-scrollbar {
  display: none; /* Safari and Chrome browsers */
}

.itemLeft {
  text-align: left;
  border-top: solid 0.3px rgb(37, 41, 48);
  margin: 0;
  padding: 15px 0;
  padding-left: 15px;
  width: 100%;
  max-height: 15px;
}

.itemRight {
  text-align: right;
  border-top: solid 0.3px rgb(37, 41, 48);
  margin: 0;
  padding: 15px 0;
  max-height: 15px;
  width: 100%;
}

.lastItem {
  display: flex;
  justify-content: flex-end;
  height: 15px;
  border-top: solid 0.3px rgb(37, 41, 48);
  margin: 0;
  padding: 15px 0;
  padding-right: 15px;
  max-height: 15px;
  width: 100%;
}

.icon {
  padding-right: 15px;
}

.ctLastItem {
  padding-right: 15px;
}

.buySide {
  color: rgb(14, 203, 129);
  text-align: right;
  border-top: solid 0.3px rgb(37, 41, 48);
  margin: 0;
  padding: 15px 0;
  max-height: 15px;
  width: 100%;
}

.sellSide {
  color: rgb(246, 70, 93);
  text-align: right;
  border-top: solid 0.3px rgb(37, 41, 48);
  margin: 0;
  padding: 15px 0;
  max-height: 15px;
  width: 100%;
}
