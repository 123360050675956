.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  border-bottom: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  background-color: rgb(30, 32, 38);
  border: solid 0.3px rgb(37, 41, 48);
  position: fixed;
  right: 0;
  z-index: 999;
}

.titleRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 20px;
}

.closeIcon :hover {
  cursor: pointer;
}

.ctMenu {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: rgb(234, 236, 239);
  margin: 0;
  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 50px;
  height: 100%;
  width: 100%;
}

.icon {
  padding-right: 20px;
}
