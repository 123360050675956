.container {
  display: flex;
  flex-direction: column;
  border-bottom: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  height: 425px;
  width: 100%;
  padding: 1px;
  overflow: hidden;
}
