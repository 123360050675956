.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
  padding-top: 10px;
}

.card {
  border-bottom: solid 0.3px rgb(37, 41, 48);
  padding: 15px;
}

.ctRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 25px;
}

.leftSide {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-height: 40px;
}

.pair {
  padding-right: 5px;
  font-size: 16px;
  line-height: 15px;
}

.typeBuy {
  color: rgb(14, 203, 129);
  font-size: 14px;
  line-height: 15px;
}

.typeSell {
  color: rgb(246, 70, 93);
  font-size: 14px;
  line-height: 15px;
}

.function {
  color: rgb(132, 142, 156);
}

.label {
  color: rgb(132, 142, 156);
}
