.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: scroll;
  border-left: solid 0.3px rgb(37, 41, 48);
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.title {
  text-align: center;
  font-size: 14px;
}

.ctTrades {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.colLabelLeft {
  margin: 5px 0;
  text-align: left;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: 400;
}

.colLabelRight {
  margin: 5px 0;
  text-align: right;
  color: rgb(132, 142, 156);
  font-size: 12px;
  font-weight: 400;
}

.ctList {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Mozilla */
}

.ctList::-webkit-scrollbar {
  display: none; /* Safari and Chrome browsers */
}

.tradesItemBuy {
  margin: 5px 0;
  color: rgb(14, 203, 129);
  text-align: left;
}

.tradesItemSell {
  margin: 5px 0;
  color: rgb(246, 70, 93);
  text-align: left;
}

.tradesItemText {
  margin: 5px 0;
  color: rgb(183, 189, 198);
  text-align: right;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .container {
    border: none;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .container {
    border: none;
  }
}
