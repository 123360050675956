.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
  height: 30px;
  width: 100%;
  padding-bottom: 10px;
}

.button {
  border: none;
  background-color: rgb(30, 32, 38);
  margin: 0;
  padding: 0;
  width: 23%;
  line-height: 5px;
  border-radius: 5px;
}

.buttonFilled {
  border: none;
  margin: 0;
  padding: 0;
  border-bottom: 1px solid rgb(37, 150, 190);
  background-color: rgb(43, 49, 57);
  width: 23%;
  line-height: 5px;
  border: none;
  border-radius: 5px;
}

.buttonLabel {
  color: rgb(132, 142, 156);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.buttonLabelFilled {
  color: rgb(37, 150, 190);
  font-size: 14px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .button {
    width: 19vw;
    line-height: 5px;
  }

  .buttonFilled {
    width: 19vw;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .button {
    width: 19vw;
    line-height: 5px;
  }

  .buttonFilled {
    width: 19vw;
  }
}
