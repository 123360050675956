.container {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 398px;
  width: 100%;
  color: rgb(234, 236, 239);
  font-size: 12px;
  font-family: "IBM Plex Sans", sans-serif;
  font-weight: 400;
}

.tabs {
  width: 50%;
}

@media screen and (max-width: 480px) and (orientation: portrait) {
  .tabs {
    width: 100%;
  }
}

@media screen and (max-height: 480px) and (orientation: landscape) {
  .tabs {
    width: 100%;
  }
}
